export const pricingData = [
  {
    title: "Free",
    type: "free",
    features: ["Sample mockups", "Personal use only", "Figma, Sketch and XD Plugins"],
    button: {
      icon: "/images/icons/downloads.svg",
      color: "linear-gradient(192.92deg, #A8DDFD 9.97%, #1986CC 82.89%)",
    },
    paddingBottom: "115px",
  },
  {
    title: "Full Library",
    type: "pro",
    features: [
      "1000+ mockups",
      "Upgrades for one full year",
      "Commercial use allowed",
      "Figma, Sketch and XD Plugins",
    ],
    button: {
      icon: "/images/icons/credit-pink.svg",
      color: "linear-gradient(338.47deg, #DB5EB8 15.24%, #F8BFEE 82.47%)",
    },
    description:
      "Mockups are downloadable. Prices will increase as Library grows. Using Angle for your team? Contact us for Team licenses.",
    paddingBottom: "100px",
  },
  {
    title: "Lifetime",
    type: "lifetime",
    features: [
      "Everything from Full Library",
      "Unlimited upgrades",
      "Early supporter limited plan",
    ],
    button: {
      icon: "/images/icons/credit-yellow.svg",
      color: "linear-gradient(16.46deg, #DA732C 31.92%, #F6D24D 88.28%)",
    },
    paddingBottom: "40px",
  },
]
