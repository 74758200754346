import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import PricingCardsSection from "./PricingCardsSection"

const PricingSection = () => {
  return (
    <Wrapper className="pricing">
      <Wave />
      <Container>
        <Title>Start creating your best presentations in minutes.</Title>
        <PricingCardsSection />
      </Container>
    </Wrapper>
  )
}

export default PricingSection

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  margin: 200px 0 0;
  padding-bottom: 160px;
  overflow: hidden;
`

const Container = styled.div`
  position: relative;
  display: grid;
  gap: 300px;
  justify-content: center;
  max-width: 1234px;
  padding: 0 20px;
  margin: 0 auto;
`

const Title = styled(TextStyles.H1H2)`
  max-width: 500px;
  margin: 0 auto;
`

const Wave = styled.div`
  position: absolute;
  max-width: 1640px;
  width: 100%;
  height: 1586px;
  top: 30px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("/images/backgrounds/pricing-wave.webp");

  @media (max-width: 1440px) {
    background-size: 1440px;
  }

  @media (prefers-color-scheme: dark) {
    max-width: 1400px;
    top: 100px;
    background-image: url("/images/backgrounds/pricing-wave-d.webp");
  }
`
