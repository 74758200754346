export const testimonials = [
  {
    avatar: "/images/avatars/kris.webp",
    name: "Kris Puckett",
    job: "Designer at InVision",
    description:
      "You know those tools that once you use, you can’t design without it? Angle is one of those tools. I’m able to work so much faster designing mockups because of Angle. Angle is super easy to use, powerful, and saves me massive amounts of time!",
  },
  {
    avatar: "/images/avatars/ahmed.webp",
    name: "Ahmed Sulaiman",
    job: "Founder of Flawless App",
    description:
      "Designers’ day-to-day job is to create visually delightful user experiences. But it’s also a designers’ responsibility to present their work to the client/team in the most attractive way. Angle is just a must-have for this purpose.",
  },
  {
    avatar: "/images/avatars/vincent.webp",
    name: "Vincent Le Moign",
    job: "Creator of Streamline",
    description:
      "Thanks to Angle, I've created amazing app mockups in just a few clicks 🙌 Mockups are the best way to show to visitors how my products can be used, and they certainly boosted the conversion rate.",
  },
]
