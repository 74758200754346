import React from "react"
import styled, { keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

import PricingCard from "../cards/PricingCard"
import { pricingData } from "../../data/PricingData"

interface StyledProps {
  inView: boolean
}

const PricingCardsSection = () => {
  const [ref, inView] = useInView()

  return (
    <Wrapper ref={ref} inView={inView}>
      {pricingData.map((data, index) => (
        <PricingCard key={index} {...data} />
      ))}
    </Wrapper>
  )
}

export default PricingCardsSection

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
 100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

const Wrapper = styled.div<StyledProps>`
  display: grid;
  grid-template-columns: 338px 400px 338px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 26px;

  > div:nth-child(1) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 1s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  > div:nth-child(2) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  > div:nth-child(3) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 1.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  @media (max-width: 1140px) {
    grid-template-columns: 1fr;
  }
`
