import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { useShowModal } from "../../states/context/ModalContext"
import useStripeCheckout from "../../utils/hooks/useStripeCheckout"
import useAuth from "../../states/context/AuthContext"
import useSubscription from "../../states/context/SubscriptionContext"
import usePricing from "../../utils/hooks/usePricing"
import * as TextStyles from "../styles/TextStyles"
import LoaderAnimation from "../animations/LoaderAnimation"
import { themes } from "../styles/ColorStyles"
import Gtag from "../../utils/classes/Gtag"

interface Props {
  type: string
  icon?: string
  color?: string
}

interface StyledProps {
  color?: string
}

const CheckoutButton = (props: Props) => {
  const { isUserLoggedIn } = useAuth()
  const { promo, isProUser } = useSubscription()
  const { redirectToCheckout, isLoading, error } = useStripeCheckout()

  const { type, icon, color } = props

  // On Safari, clicking on browser back button in Stripe checkout page causes the checkout button to keep the loading animation so force reload page
  if (typeof window !== "undefined") {
    window.onpageshow = event => {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }

  const planDescription = type === "lifetime" ? "angle lifetime" : "angle 4"
  const pricing = usePricing(promo.promoCode)
  const promoSubtitle = promo.isPromo ? `${pricing["lifetime"].discount}% OFF` : ``

  const errorMessage =
    "You already have an active purchase. You may download the full library on the downloads or account page."
  const showClientErrorModal = useShowModal("message", { logLevel: "error", message: errorMessage })
  const showServerErrorModal = useShowModal("message", { logLevel: "error", message: error })

  const title =
    type === "pro"
      ? `Purchase for $${pricing.oneYear.price}`
      : type === "lifetime"
      ? `Purchase for $${pricing.lifetime.price}`
      : type === "free"
      ? "Try for Free"
      : type === undefined && "UNDEFINED"

  let subtitle
  if (promo && promo.promoCode === "blackfriday") {
    subtitle =
      type === "pro"
        ? "Cyber Monday"
        : type === "lifetime"
        ? "Cyber Monday"
        : type === "free"
        ? "GET STARTED"
        : type === undefined && "UNDEFINED"
  } else if (promo && promo.promoCode === "sketchnews") {
    subtitle =
      type === "pro"
        ? "SKETCH NEWS"
        : type === "lifetime"
        ? "LIMITED TIME"
        : type === "free"
        ? "GET STARTED"
        : type === undefined && "UNDEFINED"
  } else {
    subtitle =
      type === "pro"
        ? "FULL LIBRARY"
        : type === "lifetime"
        ? "LIFETIME UPGRADES"
        : type === "free"
        ? "GET STARTED"
        : type === undefined && "UNDEFINED"
  }

  const callToAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (type === "pro" || type === "lifetime") {
      Gtag.sendEvent("stripe checkout button clicked", "checkout", planDescription)
    }

    if (isProUser) {
      Gtag.sendEvent("stripe checkout error", "checkout", "user already subscribed")
      showClientErrorModal()
    } else if (!isProUser) {
      if (type === "free") {
        isUserLoggedIn ? redirectToDownloads() : showSignUpModal()
      } else if (type === "pro" || type === "lifetime") {
        redirectToCheckout(type)
      }
    } else {
      emailToSupport()
    }
  }

  const showSignUpModal = useShowModal("signIn")

  const redirectToDownloads = () => {
    navigate("/downloads")
  }

  const emailToSupport = () => {
    window.location.href = "mailto:angle@designcode.io"
  }

  if (error) showServerErrorModal()

  return (
    <Wrapper className="purchase" onClick={callToAction} disabled={isLoading}>
      <Circle color={color} />
      <Container>
        <Icon className="icon" src={icon || "/images/icons/credit-pink.svg"} alt="icon" />
      </Container>
      {isLoading ? (
        <LoaderContainer>
          <LoaderAnimation />
        </LoaderContainer>
      ) : (
        <TextContainer>
          <Title>{title}</Title>
          <Caption>
            {promo.isPromo && type === "pro" && <span>{promoSubtitle} - one year</span>}
            {promo.isPromo && type === "lifetime" && <span>{promoSubtitle} - lifetime</span>}
            {!promo.isPromo && subtitle}
          </Caption>
        </TextContainer>
      )}
    </Wrapper>
  )
}

export default CheckoutButton

const Wrapper = styled.button`
  all: unset;
  position: relative;
  display: flex;
  gap: 12px;
  width: 286px;
  height: fit-content;
  background: linear-gradient(180deg, #ffffff 0%, #e6ecf0 100%);
  box-shadow: 0px 3px 5px rgba(38, 62, 84, 0.1), 0px 10px 20px rgba(38, 62, 84, 0.1),
    inset 0px -1px 0px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  cursor: pointer;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: translateY(-2px);

    .icon {
      transform: scale(1.1);
    }
  }
`

const Container = styled.div`
  display: grid;
  justify-content: center;
  position: relative;
  width: 71px;
  background: ${themes.light.pricingCard.backgroundColor};
  backdrop-filter: blur(20px);
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 2;
`

const Icon = styled.img`
  width: fit-content;
  height: fit-content;
  margin: auto;
`

const Circle = styled.div<StyledProps>`
  position: absolute;
  width: 64px;
  height: 64px;
  top: -15px;
  background: ${props =>
    props.color || "linear-gradient(338.47deg, #db5eb8 15.24%, #f8bfee 82.47%)"};
  border-radius: 50%;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  z-index: 1;
`

const LoaderContainer = styled.div`
  display: grid;
  align-items: center;
  margin: 0 auto;
  height: 70px;

  div {
    margin-right: 10px;
  }
`

const TextContainer = styled.div`
  padding: 12px;
  text-align: left;
`

const Title = styled(TextStyles.BodyMain)`
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
`

const Caption = styled(TextStyles.Caption)`
  color: #6c6a8e;
  font-weight: 600;
  text-transform: uppercase;

  span {
    color: #ff6fb0;
  }
`
