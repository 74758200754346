import React, { useState } from "react"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { navigate } from "gatsby"

import { useShowModal, useDismissModal } from "../../states/context/ModalContext"
import useAuth from "../../states/context/AuthContext"
import useSubscription from "../../states/context/SubscriptionContext"
import usePricing from "../../utils/hooks/usePricing"
import useFetchFunctions, { FunctionsEndpoint } from "../../utils/hooks/useFetchFunctions"
import config from "../../utils/config"
import Gtag from "../../utils/classes/Gtag"

import { Plans } from "../../types/subscriptionTypes"

const plans: Plans = {
  pro: {
    type: "pro",
    plan: "oneYear",
    nickname: "1 Year",
    description: "Angle 4",
  },
  lifetime: {
    type: "lifetime",
    plan: "lifetime",
    nickname: "Lifetime",
    description: "Angle Lifetime",
  },
}

interface Props {
  type: "pro" | "lifetime"
}

const PaypalCheckoutButton = ({ type }: Props) => {
  const { currentUser } = useAuth()
  const { isProUser, promo, getSubscriptionStatus } = useSubscription()
  const pricing = usePricing(promo.promoCode)
  const { fetchFunctions } = useFetchFunctions()

  const [paidFor, setPaidFor] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const showErrorModal = useShowModal("message", {
    logLevel: error,
    message: error,
  })
  const dismissModal = useDismissModal()

  const plan = plans[type]
  const price = pricing[plan.plan].price
  const promoCode = promo.isPromo ? `promo${pricing[plan.plan].discount}` : undefined
  const description = plan.description

  const handleApprove = async (orderId: string) => {
    localStorage.setItem("orderId", orderId)

    const response = await fetchFunctions(FunctionsEndpoint.paypalCheckoutCompleted, {
      currentUser,
      plan: { nickname: plan.nickname },
      orderId,
      clientUrl: config.baseUrl,
      promoCode,
    })

    if (response.status === "success") {
      Gtag.sendEvent("paypal purchase fulfilled", "checkout", description)

      setPaidFor(true)
      currentUser && getSubscriptionStatus()
    }

    if (response.status === "fail" || response.error) {
      Gtag.sendEvent("paypal checkout error", "checkout", response.error)

      const errorMessage = `Your payment was processed successfully. However, we are unable to fulfill your purchase. ${response.error}. Please contact support at angle@designcode.io for assistance and provide the order ID ${orderId}.`
      setError(errorMessage)
    }
  }

  if (paidFor) {
    dismissModal()
    navigate("/payment/success/?ref=checkout.paypal")
  }

  if (error) {
    dismissModal()
    showErrorModal()
  }

  return (
    <PayPalScriptProvider options={{ "client-id": config.paypalClientId }}>
      <PayPalButtons
        style={{
          color: "silver",
          layout: "horizontal",
          height: 48,
          tagline: false,
          shape: "rect",
        }}
        onClick={(data: any, actions: any) => {
          Gtag.sendEvent("paypal checkout button clicked", "checkout", description)

          if (isProUser) {
            setError(
              "You already have an active purchase. You may download the full library on the downloads or account page."
            )

            Gtag.sendEvent(
              "paypal checkout error",
              "checkout",
              "User already has an active subscription."
            )

            return actions.reject()
          } else {
            return actions.resolve()
          }
        }}
        createOrder={(data: any, actions: any) => {
          Gtag.sendEvent("paypal checkout started", "checkout", description)
          Gtag.sendPageview("/checkout.paypal", "Angle 4 - Paypal Checkout")

          return actions.order.create({
            purchase_units: [
              {
                description: plan.description,
                amount: {
                  value: price,
                },
                metadata: { currentUser, promo },
              },
            ],
          })
        }}
        onApprove={async (data: any, actions: any) => {
          Gtag.sendEvent("paypal checkout completed", "checkout", description, price)

          const order = await actions.order.capture()
          // console.log("order", order)
          handleApprove(data.orderID)
        }}
        onCancel={() => {
          Gtag.sendEvent("paypal checkout cancelled", "checkout", description)
        }}
        onError={(err: any) => {
          Gtag.sendEvent("paypal checkout error", "checkout", err)

          setError(err)
          console.error("PayPalCheckout onError", err)
        }}
      />
    </PayPalScriptProvider>
  )
}

export default PaypalCheckoutButton
