import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"

import * as TextStyles from "../styles/TextStyles"
import CheckoutButton from "../buttons/CheckoutButton"
import PaypalCheckoutButton from "../buttons/PaypalCheckoutButton"
import { themes } from "../styles/ColorStyles"

interface Props {
  type: string
  title: string
  features: string[]
  button: {
    icon: string
    color: string
  }
  paypal?: boolean
  description?: string
  paddingBottom?: string
}

interface StyledProps {
  paddingBottom?: string
}

const PricingCard = (props: Props) => {
  const { type, title, button, features, description, paddingBottom } = props

  return (
    <Wrapper paddingBottom={paddingBottom}>
      <Title>{title}</Title>
      <CaptionContainer>
        {features.map((feature, index) => (
          <Caption key={index}>{feature}</Caption>
        ))}
      </CaptionContainer>
      <ButtonContainer>
        <CheckoutButton type={type} {...button} />
      </ButtonContainer>
      <LazyLoad>{type !== "free" && <PaypalCheckoutButton type="pro" />}</LazyLoad>
      {description && (
        <Description>
          Mockups are downloadable. Prices will increase as Library grows. Using Angle for your
          team? <a href="mailto:angle@designcode.io">Contact us</a> for Team licenses.
        </Description>
      )}
    </Wrapper>
  )
}

export default PricingCard

const Wrapper = styled.div<StyledProps>`
  display: grid;
  gap: 30px;
  justify-content: center;
  max-width: 400px;
  width: 100%;
  height: fit-content;
  padding-top: 50px;
  padding-bottom: ${props => props.paddingBottom || "0"};

  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 30px 30px rgba(69, 42, 124, 0.15);
  backdrop-filter: blur(60px);
  border-radius: 30px;
`

const Title = styled(TextStyles.H2)`
  color: #604ad3;
`

const CaptionContainer = styled.div`
  display: grid;
  gap: 20px;
  text-align: center;
  height: fit-content;
`

const Caption = styled(TextStyles.BodyMain)`
  color: rgba(0, 0, 0, 0.7);
`

const ButtonContainer = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
`

const Description = styled(TextStyles.Caption)`
  max-width: 310px;

  a {
    text-decoration: none;
    font-weight: 600;
    color: ${themes.light.primary};

    @media (prefers-color-scheme: dark) {
      color: ${themes.dark.secondary};
    }
  }
`
