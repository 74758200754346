import React from "react"
import styled, { keyframes } from "styled-components"
import LazyLoad from "react-lazyload"
import { useInView } from "react-intersection-observer"

import * as TextStyles from "../styles/TextStyles"
import TweetCard from "../cards/TweetCard"
import FeedbackCard from "../cards/FeedbackCard"
import { testimonials } from "../../data/TestimonialData"
import TestimonialAnimationSection from "../animations/TestimonialAnimationSection"

interface StyledProps {
  inView: boolean
}

const TestimonialsSection = () => {
  const [ref, inView] = useInView()

  return (
    <Wrapper>
      <Wave />
      <Container>
        <Logo src="/images/icons/testimonial-icon.png" alt="icon" />
        <Title>Loved by 12,000</Title>
        <TestimonialAnimationSection />
        <LazyLoad>
          <TweetContainer ref={ref} inView={inView}>
            <TweetCard id="1274057899795341312" />
            <TweetCard id="1292468807995404289" />
            <TweetCard id="1217468985597403137" />
          </TweetContainer>
        </LazyLoad>
        <FeedbackContainer inView={inView}>
          {testimonials.map((testimonial, index) => (
            <FeedbackCard key={index} {...testimonial} />
          ))}
        </FeedbackContainer>
      </Container>
    </Wrapper>
  )
}

export default TestimonialsSection

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  position: relative;
  margin: 200px 0px;
`

const Container = styled.div`
  position: relative;
  text-align: center;
  margin: 0 auto;
  z-index: 2;
`

const Logo = styled.img`
  width: 66px;
  height: 66px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
`

const Title = styled(TextStyles.H2)`
  padding-top: 52px;
`

const TweetContainer = styled.div<StyledProps>`
  position: relative;
  display: grid;
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  justify-content: stretch;
  gap: 60px;
  padding-top: 300px;
  opacity: 0;
  animation: ${fadeIn} 0.5s 0.5s linear forwards;
  animation-play-state: ${props => (props.inView ? "running" : "paused")};

  @media (max-width: 659px) {
    justify-items: center;
  }

  > div:nth-child(1) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 1s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  > div:nth-child(2) {
    margin-top: -50px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 0.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};

    @media (max-width: 1119px) {
      margin-top: 0px;
    }
  }

  > div:nth-child(3) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 1.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
`

const FeedbackContainer = styled(TweetContainer)`
  padding: 120px 20px 0px;
  max-width: 1234px;
  margin: 0 auto;

  > div:nth-child(1) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 2s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  > div:nth-child(2) {
    margin-top: 0px;
    opacity: 0;
    animation: ${fadeIn} 0.5s 2.5s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }

  > div:nth-child(3) {
    opacity: 0;
    animation: ${fadeIn} 0.5s 3s linear forwards;
    animation-play-state: ${props => (props.inView ? "running" : "paused")};
  }
`

const Wave = styled.div`
  position: absolute;
  width: 100%;
  height: 658px;
  top: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/images/backgrounds/testimonial-wave.svg");

  @media (max-width: 900px) {
    top: 150px;
  }

  @media (max-width: 700px) {
    top: 50px;
  }
`
