import React from "react"
import styled from "styled-components"

import { TwitterTweetEmbed } from "react-twitter-embed"

interface Props {
  id: string
}

const TweetCard = (props: Props) => {
  const { id } = props

  return (
    <Wrapper>
      <TwitterTweetEmbed tweetId={id} />
    </Wrapper>
  )
}

export default TweetCard

const Wrapper = styled.div`
  /* max-width: 350px;
  min-width: 250px;
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  padding: 10px; */
  /* background: #ffffff; */
  /* box-shadow: 0px 30px 60px rgba(38, 46, 59, 0.3); */
  /* border-radius: 20px; */
`
