import React from "react"
import styled from "styled-components"

const TestimonialAnimation = () => {
  return (
    <Wrapper>
      <div className="pinkposition">
        <div className="polygon pinkpolygon"></div>
      </div>
      <div className="yellowposition">
        <div className="polygon yellowpolygon"></div>
      </div>
      <div className="blueposition">
        <div className="polygon bluepolygon"></div>
      </div>
      <div className="blue2position">
        <div className="polygon blue2polygon"></div>
      </div>
      <div className="purpleposition">
        <div className="polygon purplepolygon"></div>
      </div>
      <div className="appleposition">
        <div className="polygon polygonapple">
          <img
            className="logo-apple"
            src="../../images/logos/testimonial-apple.png"
            alt="logo"
          ></img>
        </div>
      </div>
      <div className="googleposition">
        <div className="polygon googlepolygon">
          <img
            className="logo-google"
            src="../../images/logos/testimonial-google.png"
            alt="logo"
          ></img>
        </div>
      </div>
      <div className="squareposition">
        <div className="polygon squarepolygon">
          <img
            className="logo-square"
            src="../../images/logos/testimonial-square.png"
            alt="logo"
          ></img>
        </div>
      </div>
      <div className="airbnbposition">
        <div className="polygon airbnbpolygon">
          <img
            className="logo-airbnb"
            src="../../images/logos/testimonial-airbnb.png"
            alt="logo"
          ></img>
        </div>
      </div>
      <div className="facebookposition">
        <div className="polygon facebookpolygon">
          <img
            className="logo-facebook"
            src="../../images/logos/testimonial-facebook.png"
            alt="logo"
          ></img>
        </div>
      </div>
      <div className="amazonposition">
        <div className="polygon amazonpolygon">
          <img
            className="logo-amazon"
            src="../../images/logos/testimonial-amazon.png"
            alt="logo"
          ></img>
        </div>
      </div>
    </Wrapper>
  )
}

export default TestimonialAnimation

const Wrapper = styled.div`
  position: relative;
  width: 1300px;
  height: 720px;
  margin: 100px auto 0;

  > * {
    position: absolute;
    transition: 1s cubic-bezier(0.2, 0.2, 0.2, 1);
    cursor: auto;
  }

  &:hover {
    .appleposition {
      left: 40px;
      top: 167px;
      &:hover {
        left: 0px;
        top: 137px;
      }
    }
    .pinkposition {
      transform: translate(50px, -20px);
      &:hover {
        transform: translate(0px, -50px);
      }
    }
    .googleposition {
      left: 200px;
      top: 255px;
      &:hover {
        left: 160px;
        top: 225px;
      }
    }
    .blueposition {
      transform: translate(45px, -20px);
      &:hover {
        transform: translate(0px, -50px);
      }
    }
    .squareposition {
      left: 486px;
      top: 436px;
      &:hover {
        left: 436px;
        top: 406px;
      }
    }
    .yellowposition {
      transform: translate(35px, -20px);
      &:hover {
        transform: translate(0px, -50px);
      }
    }
    .airbnbposition {
      left: 660px;
      top: 133px;
      &:hover {
        left: 610px;
        top: 103px;
      }
    }
    .blue2position {
      transform: translate(30px, -20px);
      &:hover {
        transform: translate(0px, -50px);
      }
    }
    .facebookposition {
      left: 967px;
      top: 35px;
      &:hover {
        left: 927px;
        top: 5px;
      }
    }
    .purpleposition {
      transform: translate(20px, -20px);
      &:hover {
        transform: translate(0px, -50px);
      }
    }
    .amazonposition {
      left: 967px;
      top: 372px;
      &:hover {
        left: 927px;
        top: 342px;
      }
    }
  }

  .squarepolygon,
  .airbnbpolygon,
  .facebookpolygon,
  .amazonpolygon,
  .googlepolygon,
  .polygonapple,
  .polygon,
  .pinkpolydon,
  .bluepolygon,
  .yellowpolygon,
  .blue2polygon,
  .purplepolygon {
    background-color: rgba(0, 0, 0, 0.1);
    clip-path: polygon(
      92.32051% 40%,
      93.79385% 43.1596%,
      94.69616% 46.52704%,
      95% 50%,
      94.69616% 53.47296%,
      93.79385% 56.8404%,
      92.32051% 60%,
      79.82051% 81.65064%,
      77.82089% 84.50639%,
      75.35575% 86.97152%,
      72.5% 88.97114%,
      69.3404% 90.44449%,
      65.97296% 91.34679%,
      62.5% 91.65064%,
      37.5% 91.65064%,
      34.02704% 91.34679%,
      30.6596% 90.44449%,
      27.5% 88.97114%,
      24.64425% 86.97152%,
      22.17911% 84.50639%,
      20.17949% 81.65064%,
      7.67949% 60%,
      6.20615% 56.8404%,
      5.30384% 53.47296%,
      5% 50%,
      5.30384% 46.52704%,
      6.20615% 43.1596%,
      7.67949% 40%,
      20.17949% 18.34936%,
      22.17911% 15.49361%,
      24.64425% 13.02848%,
      27.5% 11.02886%,
      30.6596% 9.55551%,
      34.02704% 8.65321%,
      37.5% 8.34936%,
      62.5% 8.34936%,
      65.97296% 8.65321%,
      69.3404% 9.55551%,
      72.5% 11.02886%,
      75.35575% 13.02848%,
      77.82089% 15.49361%,
      79.82051% 18.34936%
    );
  }
  .squarepolygon,
  .airbnbpolygon,
  .facebookpolygon,
  .amazonpolygon,
  .googlepolygon,
  .polygonapple,
  .blue2polygon,
  .purplepolygon,
  .yellowpolygon,
  .bluepolygon,
  .pinkpolygon,
  .polygon::before {
    content: "";
    display: block;
    position: absolute;
    left: 1px;
    top: 1px;
    backdrop-filter: blur(20px);
    clip-path: polygon(
      92.32051% 40%,
      93.79385% 43.1596%,
      94.69616% 46.52704%,
      95% 50%,
      94.69616% 53.47296%,
      93.79385% 56.8404%,
      92.32051% 60%,
      79.82051% 81.65064%,
      77.82089% 84.50639%,
      75.35575% 86.97152%,
      72.5% 88.97114%,
      69.3404% 90.44449%,
      65.97296% 91.34679%,
      62.5% 91.65064%,
      37.5% 91.65064%,
      34.02704% 91.34679%,
      30.6596% 90.44449%,
      27.5% 88.97114%,
      24.64425% 86.97152%,
      22.17911% 84.50639%,
      20.17949% 81.65064%,
      7.67949% 60%,
      6.20615% 56.8404%,
      5.30384% 53.47296%,
      5% 50%,
      5.30384% 46.52704%,
      6.20615% 43.1596%,
      7.67949% 40%,
      20.17949% 18.34936%,
      22.17911% 15.49361%,
      24.64425% 13.02848%,
      27.5% 11.02886%,
      30.6596% 9.55551%,
      34.02704% 8.65321%,
      37.5% 8.34936%,
      62.5% 8.34936%,
      65.97296% 8.65321%,
      69.3404% 9.55551%,
      72.5% 11.02886%,
      75.35575% 13.02848%,
      77.82089% 15.49361%,
      79.82051% 18.34936%
    );
  }
  .facebookposition {
    position: absolute;
    left: 927px;
    top: 55px;
  }
  .facebookpolygon {
    width: 243px;
    height: 243px;
  }
  .facebookpolygon::before {
    width: 243px;
    height: 243px;
    background: rgba(255, 255, 255, 0.4);
  }
  .logo-facebook {
    position: relative;
    max-width: 175px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 105px;
    mix-blend-mode: overlay;
  }
  .amazonposition {
    position: absolute;
    width: 210.44px;
    height: 198.32px;
    left: 927px;
    top: 392px;
  }
  .amazonpolygon {
    width: 210px;
    height: 210px;
  }
  .amazonpolygon::before {
    width: 210px;
    height: 210px;
    background: rgba(255, 255, 255, 0.4);
  }
  .logo-amazon {
    position: relative;
    max-width: 155px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 95px;
    mix-blend-mode: overlay;
  }
  .airbnbposition {
    position: absolute;
    left: 610px;
    top: 153px;
  }
  .airbnbpolygon {
    width: 375px;
    height: 375px;
  }
  .airbnbpolygon::before {
    width: 375px;
    height: 375px;
    background: rgba(255, 255, 255, 0.4);
  }
  .logo-airbnb {
    position: relative;
    max-width: 264px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 150px;
    mix-blend-mode: overlay;
  }
  .googleposition {
    position: absolute;
    left: 160px;
    top: 275px;
  }
  .googlepolygon {
    width: 337px;
    height: 337px;
  }
  .googlepolygon::before {
    width: 337px;
    height: 337px;
    background: rgba(255, 255, 255, 0.4);
  }
  .logo-google {
    position: relative;
    max-width: 219px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 140px;
    mix-blend-mode: overlay;
  }
  .squareposition {
    position: absolute;
    left: 436px;
    top: 456px;
  }
  .squarepolygon {
    width: 289px;
    height: 289px;
  }
  .squarepolygon::before {
    width: 289px;
    height: 289px;
    background: rgba(255, 255, 255, 0.4);
  }
  .logo-square {
    position: relative;
    max-width: 224px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 120px;
    mix-blend-mode: overlay;
  }
  .purpleposition {
    position: absolute;
    left: 758px;
    top: 413px;
  }
  .purplepolygon {
    width: 246px;
    height: 246px;
  }
  .purplepolygon::before {
    width: 246px;
    height: 246px;
    background: linear-gradient(161.74deg, #a8d4fd 6.44%, #8819cc 85.79%);
  }
  .blue2position {
    position: absolute;
    left: 299px;
    top: 515px;
  }
  .blue2polygon {
    width: 201px;
    height: 201px;
  }
  .blue2polygon::before {
    width: 201px;
    height: 201px;
    background: linear-gradient(161deg, #a8ddfd 6.9%, #1986cc 85.41%);
    border: 0.2px solid rgba(255, 255, 255, 0.5);
  }
  .blueposition {
    position: absolute;
    left: 818px;
    top: 0px;
  }
  .bluepolygon {
    width: 410px;
    height: 410px;
  }
  .bluepolygon::before {
    width: 410px;
    height: 410px;
    background: linear-gradient(161.25deg, #a8ddfd 6.75%, #1986cc 85.54%);
  }
  .yellowposition {
    position: absolute;
    left: 328px;
    top: 150px;
  }
  .yellowpolygon {
    width: 440px;
    height: 440px;
  }
  .yellowpolygon::before {
    width: 440px;
    height: 440px;
    background: linear-gradient(345.1deg, #da732c 28.92%, #f6d24d 94.61%);
  }
  .pinkposition {
    position: absolute;
    left: 33px;
    top: 251px;
  }
  .pinkpolygon {
    width: 300px;
    height: 300px;
  }
  .pinkpolygon::before {
    width: 300px;
    height: 300px;
    background: linear-gradient(305.73deg, #db5eb8 14.55%, #f8bfee 85.33%);
  }
  .polygonapple {
    width: 212px;
    height: 212px;
  }
  .polygonapple::before {
    width: 212px;
    height: 212px;
    background: rgba(255, 255, 255, 0.4);
  }
  .logo-apple {
    position: relative;
    max-width: 72px;
    width: 100%;
    height: auto;
    left: 0px;
    top: 60px;
    mix-blend-mode: overlay;
  }
  .appleposition {
    position: absolute;
    left: 0px;
    top: 187px;
  }
`
