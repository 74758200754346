import React from "react"
import styled, { keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

import * as TextStyles from "../styles/TextStyles"
import { faq } from "../../data/FaqData"
import FaqCard from "../cards/FaqCard"

interface StyledProps {
  inView: boolean
}

const FaqSection = () => {
  const [ref, inView] = useInView()

  return (
    <Wrapper ref={ref} inView={inView}>
      <Container>
        <Title>Frequently Asked Questions</Title>
        <CardContainer>
          {faq.map((data, index) => (
            <MasonryBrick key={index}>
              <FaqCard {...data} />
            </MasonryBrick>
          ))}
        </CardContainer>
      </Container>
    </Wrapper>
  )
}

export default FaqSection

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`

const Wrapper = styled.div<StyledProps>`
  text-align: center;
  margin: 200px 0;
  opacity: 0;
  animation: ${fadeIn} 2s 1s linear forwards;
  animation-play-state: ${props => (props.inView ? "running" : "paused")};
`

const Container = styled.div`
  display: grid;
  gap: 60px;
  justify-content: center;
  justify-items: center;
  max-width: 1040px;
  padding: 0 20px;
  margin: 0 auto;
`

const CardContainer = styled.div`
  width: fit-content;
  column-count: 2;
  column-gap: 40px;

  @media (max-width: 700px) {
    display: grid;
    justify-content: center;
  }

  @media (max-width: 500px) {
    padding: 0 20px;
  }
`

const MasonryBrick = styled.div`
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
`

const Title = styled(TextStyles.H1H2)``
