import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  title: string
  description: string
}

const FaqCard = (props: Props) => {
  const { title, description } = props

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Wrapper>
  )
}

export default FaqCard

const Wrapper = styled.div`
  display: grid;
  max-width: 500px;
  gap: 15px;
  padding: 30px 40px;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  text-align: left;
  margin: 0 auto;
`

const Title = styled(TextStyles.H5)`
  color: #201e44;
`

const Description = styled(TextStyles.MediumText)`
  color: #6c6a8e;
  line-height: 30px;
`
