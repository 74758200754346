import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"

import TestimonialAnimation from "./TestimonialAnimation"
import useWindowSize from "../../utils/hooks/useWindowSize"

interface StyledProps {
  width: number
}

const TestimonialAnimationSection = () => {
  const { width } = useWindowSize()

  return (
    <Wrapper>
      <LazyLoad height={720}>
        <MockupWrapper width={width}>
          <TestimonialAnimation />
        </MockupWrapper>
      </LazyLoad>
    </Wrapper>
  )
}

export default TestimonialAnimationSection

const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  width: 100%;
  overflow: hidden;
`

const MockupWrapper = styled.div<StyledProps>`
  @media (max-width: 1300px) {
    transform-origin: top left;
    transform: scale(${props => props.width / 1300});
    height: ${props => (props.width / 1300) * 720}px;
  }
`
