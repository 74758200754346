import { useState, useEffect } from "react"

import { Pricing } from "../../types/subscriptionTypes"

const initialState: Pricing = {
  oneYear: {
    price: 79,
    discount: 0,
  },
  lifetime: {
    price: 149,
    discount: 0,
  },
}

const usePricing = (promoCode?: string) => {
  const [pricing, setPricing] = useState(initialState)

  useEffect(() => {
    const result = getPricing()
    setPricing(result)
  }, [promoCode])

  const getPricing = () => {
    switch (promoCode) {
      // 50% discount
      case "designcode":
      case "shape":
      case "student":
      // case "blackfriday":
        // console.log("50% off")
        return {
          oneYear: { price: 39, discount: 50 },
          lifetime: { price: 69, discount: 50 },
        }

      // 40% discount
      // case "producthunt":
      case "sketchnews":
      case "L1REVY":
        // console.log("40% off")
        return {
          oneYear: { price: 49, discount: 40 },
          lifetime: { price: 89, discount: 40 },
        }

      // Invalid promo code
      default:
        // console.log("Invalid promo code")
        return {
          oneYear: { price: 79, discount: 0 },
          lifetime: { price: 149, discount: 0 },
        }
    }
  }

  return pricing
}

export default usePricing
