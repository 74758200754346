import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  avatar: string
  name: string
  job: string
  description: string
}

const FeedbackCard = (props: Props) => {
  const { avatar, name, job, description } = props

  return (
    <Wrapper>
      <Container>
        <TextWrapper>
          <Avatar src={avatar} alt="avatar" />
          <TextContainer>
            <Title>{name}</Title>
            <Job>{job}</Job>
          </TextContainer>
        </TextWrapper>
        <Description>{description}</Description>
      </Container>
    </Wrapper>
  )
}

export default FeedbackCard

const Wrapper = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 27px 30px;
  background: #ffffff;
  text-align: left;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`

const Container = styled.div`
  display: grid;
  gap: 20px;
`

const TextWrapper = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  gap: 20px;
`

const TextContainer = styled.div``

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  margin: 0;
`

const Title = styled(TextStyles.BodyMain)`
  font-weight: 500;
  color: #000000;
`

const Job = styled(TextStyles.MediumText)`
  color: #6c6a8e;
`

const Description = styled(TextStyles.MediumText)`
  color: #000000;
`
