import { useState } from "react"
import { Stripe, loadStripe } from "@stripe/stripe-js"

import useAuth from "../../states/context/AuthContext"
import useSubscription from "../../states/context/SubscriptionContext"
import config from "../config"
import useFetchFunctions, { FunctionsEndpoint } from "./useFetchFunctions"
import usePricing from "./usePricing"
import Gtag from "../classes/Gtag"

let stripePromise: Promise<Stripe | null>

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripeKey)
  }

  return stripePromise
}

const useStripeCheckout = () => {
  const [state, setState] = useState({ error: null, isLoading: false })
  const { currentUser } = useAuth()
  const { promo } = useSubscription()
  const pricing = usePricing(promo.promoCode)
  const { fetchFunctions } = useFetchFunctions()

  const redirectToCheckout = async (planType: string) => {
    setState({ ...state, isLoading: true })
    const planNickname = planType === "lifetime" ? "Lifetime" : "1 Year"
    const planDescription = planType === "lifetime" ? "angle lifetime" : "angle 4"
    const plan = planType === "lifetime" ? planType : "oneYear"
    const promoCode = promo.isPromo ? `promo${pricing[plan].discount}` : undefined

    const gaClientId = Gtag.getClientId()

    try {
      // Call functions to provide checkout session ID
      const response = await fetchFunctions(FunctionsEndpoint.stripeCheckoutCreate, {
        currentUser,
        plan: { nickname: planNickname },
        clientUrl: config.baseUrl,
        promoCode,
        gaClientId,
      })

      if (response.status === "fail") {
        setState({ error: response.error, isLoading: false })
        Gtag.sendEvent("stripe checkout error", "checkout", response.error)
      } else {
        Gtag.sendEvent("stripe checkout started", "checkout", planDescription)
        Gtag.sendPageview("/checkout.stripe", "Angle 4 - Stripe Checkout")

        const stripe = await getStripe()
        const { error } = await stripe!.redirectToCheckout({ sessionId: response.session.id })

        // If `redirectToCheckout` fails due to a browser or network error
        console.warn(error.message)
        setState({ error: response.error, isLoading: false })
      }
    } catch (error: any) {
      setState({ error: error.message, isLoading: false })
      Gtag.sendEvent("stripe checkout error", "checkout", error.message)
    }
  }

  return { redirectToCheckout, ...state }
}

export default useStripeCheckout

// https://www.gatsbyjs.com/tutorial/ecommerce-tutorial/
// https://vercel.com/guides/getting-started-with-nextjs-typescript-stripe
