export const faq = [
  {
    title: "Which payment method do you accept?",
    description: "We accept credit card and payment with PayPal.",
  },
  {
    title: "How does your license work?",
    description:
      "You may use the Angle mockups for your personal and commercial projects. 1 License per user. If your team uses the Angle Library, please contact us. You may not redistribute or resell.",
  },
  {
    title: "Do I need to pay for newer devices?",
    description:
      "As long as you're within your one-year period of free upgrades, you always get access to the updated package for Sketch, Figma and XD.",
  },
  {
    title: "Figma or Sketch? :)",
    description:
      "We love and actively use both! Figma is fantastic for collaboration and feedback. Sketch is more mature, has better resources and is truly native. We're 100% invested in both. We think you'll love the Angle Library of Figma Components / Sketch Symbols.",
  },
]
